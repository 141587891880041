/*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */

$bobby: "bobby", "times new roman", serif;
$source-sans: "Source Sans Pro", "system-ui", sans-serif;
$korolev: "korolev", sans-serif;
$montserrat: "montserrat", sans-serif;
$nexa: "nexa", sans-serif;

/* ============================================================ *\
|* Colors
\* ============================================================ */

$white: #fff;
$grey-light: #e6e7e8;
$pop: #e37625;
$secondary-pop: #00a3dd;
$secondary-light: #9aadce;
$sec: #436fa0;
$secondary-shade: #0e2542;
$secondary-dark: #030a12;
$black: #01060b;

/* ============================================================ *\
|* Breakpoints (bp)
\* ============================================================ */

$bp-sm: 480px;
$bp-md: 640px;
$bp-lg: 960px;
$bp-xl: 1280px;

/* ============================================================ *\
 * Font sizes
\* ============================================================ */

$s1-mobile: 12vw;
$s2-mobile: 10vw;
$s3-mobile: 8vw;
$s4-mobile: 7vw;
$s5-mobile: 6vw;
$s6-mobile: 5vw;
$s7-mobile: 4vw;
$s8-mobile: 3.6vw;

$s1: 54px;
$s2: 40px;
$s3: 36px;
$s4: 32px;
$s5: 27px;
$s6: 22px;
$s7: 18px;
$s8: 16px;

/* ============================================================ *\
 * Font Weights
\* ============================================================ */
$bold: bold;
$normal: normal;

[data-theme="light"] {
  --bg-color: #ffffff;
  --text-color: #000000;
}

[data-theme="dark"] {
  --bg-color: #000000;
  --text-color: #ffffff;
}



:root {
  --pop-1: #FF00FF;
  --pop-2: #00FFFF;
  color: var(--text-color);
  background-color: var(--bg-color);
  .button, .projectCard {
    mix-blend-mode: unset;
  }
}


.dark-mode {
  .button, .projectCard {
    mix-blend-mode: unset;
  }
}

