/*
 * Typography
 *
 * Website-wide text styling
 *
 */

@import "variables.scss";
@import "mixins.scss";

//PROJECT FONTS
@font-face {
  font-family: 'Gooper';
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Gooper7-SemiBold.woff2") format('woff2');
}

@font-face {
  font-family: 'Gooper';
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Gooper7-SemiBoldItalic.woff2") format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

// Type styling

* {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  vertical-align: inherit;
  line-height: 1.2em;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

a {
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  @include smoothness(.2s);
  &:hover {
    font-weight: 700;
  }
}

strong {
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0px;
}

p,
li,
a,
blockquote,
h4 {
  font-size: 1.2rem;
}

h1 {
  font-size: 7rem;
  font-family: "Gooper", sans-serif;
}

h2 {
  font-size: 4rem;
  font-weight: 400;
}

h3 {
  font-size: 1.4rem;
}

p {
  margin: 0px;
}

p + p {
  margin-top: 0.75em;
}

li + li {
  margin-top: 0.5em;
}

strong {
  font-weight: 500;
  @include smoothness(0.2s); 
  &:hover {
    font-weight: 700;
  }
}
