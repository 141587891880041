.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  background-color: rgb(49, 57, 0);
  color:rgb(201, 255, 208);
  padding: 40px;
  text-align: center;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home * {
  z-index: 3;
}

h1 {
  line-height: 1em;
}

h2{
  padding-bottom: 60px;
}

a {
  color:rgb(143, 251, 217);
}

p {
  max-width: 520px;
}

.logo {
  width: 300px;
  justify-self: flex-end;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100dvh;
  object-fit: cover;
  filter: brightness(50%);
}

.scrim {
  height: 100%;
  width: 100%;
  position: absolute;
  background:url(
    data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAAP0lEQVQoU42Q0QoAIAgDz///aMPAKKeQb26HTg0tB6zKVUhI4Bus5tMn2K4Djh7gBGXM7Uvo5riQ/BfUNwwTWfn+Cgg4zkoRAAAAAElFTkSuQmCC         
  ) repeat;
  animation-name: MOVE-BG;
  animation-duration: .4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: 2;
  }

@media screen and (max-width: 780px) {
  .home {
    padding: 20px;
  }

  h1 {
    font-size: 14vw;
  }
  h2 {
    font-size: 8vw;
  }
}